/** import fonts */
@import url('http://fonts.cdnfonts.com/css/arial');
@import url('http://fonts.cdnfonts.com/css/helvetica-neue-9');
@import url('http://fonts.cdnfonts.com/css/myriad-pro');

* {
  /** define colours */
  --page-background-colour: #d2e1ff;
  --submit-button: #5854aa;

  /** define fonts */
  --form-header-font: 'Arial', sans-serif;
  --form-label-font: 'Helvetica Neue', sans-serif;
  --form-button-font: 'Myriad Pro', sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
}
