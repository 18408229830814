/*!
 * DetailsForm.css
 * E-mail: jeoffmorris@icloud.com
 * Author Jeoff Morris 
 */
.details-form {
  width: 410px;
  height: min-content;
  padding: 3px;
  --form-background: #dedede;
  background-color: #fff;
  border-radius: 7px;
  font-family: var(--form-label-font);
}

.form-section {
  background-color: var(--form-background);
  border-radius: 5px;
  margin-bottom: 3px;
}

.details-form .header {
  cursor: pointer;
  width: 100%;
  background: #ffc400;
  background: linear-gradient(0deg, #eea20e 0%, #ffc400 100%);
  border-radius: 5px;
  font-family: var(--form-header-font);
}

.header-title {
  font-size: 12px;
  color: white;
  text-shadow: 1px 1px #686868;
  padding: 13px;
  line-height: 46px;
}

.form-group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 120px;
}

label {
  display: inline-block;
  font-size: 11px;
  margin-left: 8px;
  margin-top: 8px;
  margin-bottom: 5px;
  width: 146px;
}

input[type='text'],
input[type='email'],
input[type='tel'] {
  height: 28px;
  margin-left: 5px;
  margin-right: 6px;
  width: 140px;
  border-radius: 9px;
  outline: none;
  border: none;
  box-shadow: inset 1px 1px 3px #808080b0, inset -1px -1px 3px #808080b0;
  padding-left: 2px;
  color: #808080;
}

input[type='number'] {
  width: 30px;
  height: 28px;
  margin-left: 4px;
  border-radius: 9px;
  outline: none;
  border: none;
  box-shadow: inset 1px 1px 3px #808080b0, inset -1px -1px 3px #808080b0;
  padding-left: 2px;
  color: #808080;
}

.next {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 9px;
}

button {
  border: none;
  text-align: center;
  width: 90px;
  border-radius: 5px;
  padding: 5.5px;
  background-color: var(--submit-button);
  font-family: var(--form-button-font);
  font-size: 11px;
  color: #fff;
  background: rgb(64, 74, 181);
  background: linear-gradient(
    0deg,
    rgba(117, 99, 213, 1) 0%,
    rgba(64, 74, 181, 1) 100%
  );
  letter-spacing: 1px;
  cursor: pointer;
}

.date-form-group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 20px;
}

select {
  border: none;
  color: #808080;
  margin-left: 2px;
  height: 30px;
  width: 140px;
  border-radius: 9px;
  background: linear-gradient(0deg, #9c9c9c 0%, #ffffff 100%);
  box-shadow: inset 1px 1px 3px #808080b0, inset -1px -1px 3px #808080b0;
}

.year {
  width: 10px;
}

.final-group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.comments {
  display: inline-block;
  font-size: 12px;
  margin-left: 8px;
  margin-top: 12px;
  margin-bottom: 6px;
}

textarea {
  margin-left: 8px;
  box-shadow: inset 1px 1px 3px #808080b0, inset -1px -1px 3px #808080b0;
  resize: none;
  border-radius: 7px;
  border: none;
  color: #808080;
}

.submit {
  margin-right: 8px;
  margin-top: 88px;
}

.button {
  margin-bottom: 6px;
}

.modal {
  position: fixed;
  z-index: 10;
  display: none;
  place-content: center;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: #4e484891;
}

.modal-container {
  position: relative;
  padding: 3px;
  min-width: 360px;
  width: 34vw;
  height: min-content;
  min-height: 200px;
  border-radius: 7px;
  background-color: #ffffff;
}

.modal .header {
  width: 100%;
  background: #ffc400;
  background: linear-gradient(0deg, #eea20e 0%, #ffc400 100%);
  border-radius: 5px;
  font-family: var(--form-header-font);
}

.errors {
  padding: 6px;
}

.errors .error {
  color: #961717;
  font-size: 12px;
  text-transform: capitalize;
}

.success {
  font-size: 12px;
  text-transform: capitalize;
}

@media screen and (max-width: 460px) {
  .details-form {
    transform: scale(50%);
  }
}